import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

const StandardPageTemplate = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  // const { html, frontmatter } = data.markdownRemark
  const { mdx } = data
  const { body, frontmatter } = mdx

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
      />
      <header>
        <h1
          style={{
            marginTop: rhythm(1.5),
            marginBottom: rhythm(1),
          }}
        >
          {frontmatter.title}
        </h1>
      </header>
      <MDXProvider >
        <MDXRenderer>{body}</MDXRenderer>
      </MDXProvider>
    </Layout>
  )
}

export default StandardPageTemplate

export const pageQuery = graphql`
  query PageQuery($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(slug: {eq: $slug}) {
      id
      body
      slug
      frontmatter {
        title
        description
      }
    }
  }
`